import $ from "jquery";
import Backbone from "backbone";
import _ from "underscore";
import moment from "moment";
import Uri from "jsuri";
import extoleInvite from "src/shared/third-party-integrations/extole-invite";
import DashboardTestModeActionsView from "src/shared/dashboard-2.0/DashboardTestModeActionsView";
import ajaxPrefilter from "src/shared/store/auth/ajax-prefilter";
import axiosPrefilter from "src/shared/store/auth/axios-prefilter";
import queryStringService from "src/shared/query-string-service";

const EXTOLE_CLIENT_NAME = "extole";
const DATE_FIELD_PREFIX = "extole_dashboard_";
const START_TIME_FIELD_NAME = `${DATE_FIELD_PREFIX}start_time`;
const END_TIME_FIELD_NAME = `${DATE_FIELD_PREFIX}end_time`;
const CLASSIC_DATE_FIELD_PREFIX = "extole_classic_dashboard_";
const CLASSIC_START_TIME_FIELD_NAME = `${CLASSIC_DATE_FIELD_PREFIX}start_date`;
const CLASSIC_END_TIME_FIELD_NAME = `${CLASSIC_DATE_FIELD_PREFIX}end_date`;
const REPORTS_PREFIX = "extole_reports_";
const REPORTS_TAG_FIELD_NAME = `${REPORTS_PREFIX}tag`;
const REPORTS_QUERY_SEARCH_FIELD_NAME = `${REPORTS_PREFIX}query_search`;
const CLIENT_CREATED = "client_created";
const CLIENT_LAUNCHED = "client_launched";
const MODE_FIELD_NAME = "extole_dashboard_mode";
const LOGGED_IN_TIMESTAMP = "extole_logged_in_timestamp";
const REPORT_SEARCH_QUERY = "extole_reports_search_query";
const REPORTS_PAGES = ["/reports", "/labs/report-types"];
const SEARCH_QUERY = "search_query";
const MODES = {
    PRODUCTION: "production",
    TEST: "test",
};

const CLIENT_MODEL_PICK_ATTRIBUTES = [
    "sftp_public_key",
    "time_zone",
    "file_processing_enabled",
];
const EXTOLE_NAVBAR_IS_COLLAPSED = "extole_navbar_is_collapsed";
const MAX_TIMEOUT = 2147483647;

function ModeService() {
    this.setMode = function (mode) {
        localStorage.setItem(MODE_FIELD_NAME, mode || MODES.PRODUCTION);
        document.location.reload();
    };

    this.getMode = function () {
        return localStorage.getItem(MODE_FIELD_NAME) || MODES.PRODUCTION;
    };

    this.isTestMode = function () {
        return this.getMode() === MODES.TEST;
    };

    this.addPageFunctionality = function () {
        if (this.isTestMode()) {
            const actionsView = DashboardTestModeActionsView.create();
            actionsView.on(
                DashboardTestModeActionsView.EVENTS.EXIT_TEST_MODE,
                this.setMode.bind(this, MODES.PRODUCTION)
            );
        }
    };
}

const AccessToken = Backbone.Model.extend({
    urlRoot: "/api/v4/tokens",
    idAttribute: "access_token",
    initialize() {
        this.logoutTimeout = null;
        this.on("sync", () => {
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
            }
            const expiresInMilliseconds =
                this.get("expires_in") * 1000 > MAX_TIMEOUT
                    ? MAX_TIMEOUT
                    : this.get("expires_in") * 1000;
            this.logoutTimeout = setTimeout(
                this.remove.bind(this),
                expiresInMilliseconds
            );
        });
        this.on("error", (model, response) => {
            if (response.status == 403) {
                this.remove();
            }
        });
    },
    sync(method, model, options) {
        if (method === "update") {
            method = "create";
        }
        if (method === "create") {
            options.url = this.urlRoot;
            options.attrs = _.pick(
                this.attributes,
                "client_id",
                "email",
                "password"
            );
        }
        if (method === "read") {
            options.url = this.urlRoot;
        }
        return Backbone.sync.call(this, method, model, options);
    },
    remove() {
        localStorage.removeItem("extoleAccessToken");
        if (this.id) {
            $.when(this.destroy()).always(() => {
                this.clear();
            });
        }
    },
});

const MeModel = Backbone.Model.extend({
    url: "/api/v2/me",
});

const TimelineModel = Backbone.Model.extend({
    idAttribute: "name",
});

const TimelineCollection = Backbone.Collection.extend({
    url: "/api/v2/timeline-entries",
    model: TimelineModel,
    getLaunchDate() {
        const clientLaunchedModel =
            this.get(CLIENT_LAUNCHED) || this.get(CLIENT_CREATED);
        return (
            clientLaunchedModel && moment.utc(clientLaunchedModel.get("date"))
        );
    },
});

const ClientModel = Backbone.Model.extend({
    idAttribute: "client_id",
});

const ClientCollection = Backbone.Collection.extend({
    url: "/api/v2/me/clients",

    model: ClientModel,

    fetch: _.memoize(function () {
        return Backbone.Collection.prototype.fetch.call(this);
    }),

    getExtoleClient() {
        return this.findWhere({ name: EXTOLE_CLIENT_NAME });
    },
});

const ClientSettingsModel = Backbone.Model.extend({
    url: "/api/v2/clients/current/settings",

    isNew() {
        return false;
    },

    initialize() {
        this.timelineCollection = new TimelineCollection();
    },

    fetch() {
        const deferred = $.Deferred();

        $.when(
            Backbone.Model.prototype.fetch.call(this),
            this.timelineCollection.fetch()
        )
            .done(deferred.resolve)
            .fail(deferred.reject);

        return deferred;
    },

    sync(method, model, options) {
        if (method === "update") {
            options.attrs = _.pick(
                this.attributes,
                CLIENT_MODEL_PICK_ATTRIBUTES
            );
        }

        return Backbone.Model.prototype.sync.call(this, method, model, options);
    },

    getTimezone() {
        return this.get("time_zone");
    },

    getTimeline() {
        return this.timelineCollection;
    },
});

function removeUnusedParameters() {
    if (REPORTS_PAGES.includes(document.location.pathname)) {
        queryStringService.removeParameter(SEARCH_QUERY);
    }
}

function clearData() {
    localStorage.removeItem(START_TIME_FIELD_NAME);
    localStorage.removeItem(END_TIME_FIELD_NAME);
    localStorage.removeItem(CLASSIC_START_TIME_FIELD_NAME);
    localStorage.removeItem(CLASSIC_END_TIME_FIELD_NAME);
    localStorage.removeItem(REPORTS_QUERY_SEARCH_FIELD_NAME);
    localStorage.removeItem(REPORTS_TAG_FIELD_NAME);
    localStorage.removeItem(REPORT_SEARCH_QUERY);
}

function setLoggedInTimestamp() {
    localStorage.setItem(LOGGED_IN_TIMESTAMP, new Date().getTime());
}

function clearLoggedInTimestamp() {
    localStorage.removeItem(LOGGED_IN_TIMESTAMP);
}

function Session(accessTokenValue) {
    const self = this;
    const modeService = new ModeService();
    const clientCollection = new ClientCollection();
    const clientModel = new ClientSettingsModel();
    const accessToken = new AccessToken();
    const me = new MeModel();
    let interval;

    if (accessTokenValue) {
        localStorage.extoleAccessToken = accessTokenValue;
    }

    function pingToken() {
        if (window.localStorage.getItem("disableAccessTokenPolling")) {
            return;
        }
        interval = setInterval(accessToken.fetch.bind(accessToken), 10000);
    }

    function fetchMe(callback) {
        $.when(me.fetch(), clientModel.fetch(), clientCollection.fetch())
            .done(callback.bind(null, null))
            .fail(callback.bind(null));
    }

    this.getClient = function () {
        return clientModel;
    };

    this.getClientName = function () {
        return this.getMeClientResponse().name || "";
    };

    this.getClients = function () {
        return clientCollection;
    };

    this.getMeClientResponse = function () {
        try {
            return clientCollection
                .findWhere({
                    client_id: this.getClientId(),
                })
                .toJSON();
        } catch (e) {
            return {};
        }
    };

    this.stopPing = function () {
        clearInterval(interval);
    };

    this.startPing = function () {
        pingToken();
    };

    this.ajaxPrefilter = ajaxPrefilter.start(() => {
        this.logout();
    });

    this.axiosPrefilter = axiosPrefilter.start(() => {
        this.logout();
    });

    this.updateAccessToken = function (accessTokenData) {
        accessToken.set(accessTokenData, { silent: true });
    };

    this.start = function (callback) {
        extoleInvite.bootstrap();
        callback = callback || function () {};
        const parameters = {};
        const accessTokenFromUrl = new Uri(window.location).getQueryParamValue(
            "access_token"
        );
        if (accessTokenFromUrl) {
            parameters.access_token = accessTokenFromUrl;
        }
        accessToken
            .fetch({
                data: parameters,
            })
            .done(() => {
                if (self.isSuperUser()) {
                    const currentTokenClientId = accessToken.get("client_id");
                    const urlClientId = getClientIdFromUrl();

                    if (urlClientId && urlClientId !== currentTokenClientId) {
                        self.update(
                            {
                                client_id: urlClientId,
                            },
                            (error) => {
                                if (error) {
                                    const uri = new Uri(window.location);
                                    uri.deleteQueryParam("client_id");
                                    window.history.replaceState(
                                        {},
                                        null,
                                        uri.toString()
                                    );
                                    onFail(error);
                                } else {
                                    onSuccess(callback);
                                }
                            }
                        );
                    } else {
                        onSuccess(callback);
                    }
                } else {
                    onSuccess(callback);
                }
            })
            .fail(onFail);

        function onSuccess(callback) {
            localStorage.extoleAccessToken = accessToken.get("access_token");
            pingToken();
            if (self.isExternalUser()) {
                callback();
            } else {
                fetchMe(callback);
            }
        }

        function onFail(response) {
            let error;
            if (response.responseJSON) {
                error = new Error(response.responseJSON.message);
            } else {
                error = new Error(response.statusText);
            }
            callback(error);
        }
    };

    this.emailPasswordLogin = function (email, password, callback) {
        callback = callback || function () {};
        accessToken
            .save(
                {
                    email,
                    password,
                },
                { wait: true }
            )
            .done(() => {
                localStorage.extoleAccessToken =
                    accessToken.get("access_token");
                setLoggedInTimestamp();
                pingToken();
                fetchMe(callback);
            })
            .fail((response) => {
                callback(response);
            });
    };

    this.logout = function () {
        localStorage.setItem("previousUrl", "");
        localStorage.setItem(EXTOLE_NAVBAR_IS_COLLAPSED, "false");
        accessToken.remove();
        clearLoggedInTimestamp();
        clearData();
    };

    this.update = function (properties, callback) {
        callback = callback || function () {};
        localStorage.removeItem("extoleAccessToken");
        accessToken
            .save(properties, { wait: true })
            .done(() => {
                localStorage.extoleAccessToken =
                    accessToken.get("access_token");
                fetchMe(callback);
            })
            .fail((response) => {
                let error;
                if (response.responseJSON) {
                    error = new Error(response.responseJSON.message);
                } else {
                    error = new Error(response.statusText);
                }
                callback(error);
            });
    };

    this.isLoggedIn = function () {
        return accessToken.has("access_token");
    };

    this.isSuperUser = function () {
        const scopes = accessToken.get("scopes") || [];
        return _(scopes).contains("CLIENT_SUPERUSER");
    };

    this.isExternalUser = function () {
        const scopes = accessToken.get("scopes") || [];
        return scopes.length === 1 && _(scopes).contains("SHARED_RESOURCE");
    };

    this.isUserSupportUser = function () {
        const scopes = accessToken.get("scopes") || [];
        const isPlainUser =
            scopes.includes("CLIENT_ADMIN") ||
            scopes.includes("CLIENT_SUPERUSER");
        return scopes.includes("USER_SUPPORT") && !isPlainUser;
    };

    this.getAuthenticationMethods = function (email) {
        const data = {};
        if (email) {
            data.email = email;
        }
        return $.ajax({
            type: "POST",
            url: "/api/v1/auth-methods/discover",
            data: JSON.stringify(data),
            contentType: "application/json",
            accept: "application/json",
        });
    };

    this.onChange = function (callback) {
        accessToken.on("change:access_token", onAccessTokenChanged);
        accessToken.on("change:client_id", onAccessTokenChanged);

        function onAccessTokenChanged() {
            removeUnusedParameters();
            clearData();
            callback();
        }
    };

    this.offChange = function (callback) {
        accessToken.off("change", callback);
    };

    this.getAccessToken = function () {
        return accessTokenValue || accessToken.get("access_token");
    };

    this.getClientId = function () {
        return me.get("client_id");
    };

    this.getEmail = function () {
        return me.get("email") ?? "";
    };

    this.isExtoleUser = function () {
        return Boolean(this.getEmail().match(/extole\.com$/));
    };

    this.isTestUser = function () {
        return Boolean(this.getEmail().match(/mailosaur\.io$/));
    };

    /* Nullable */
    this.getUserId = function () {
        return me.get("user_id");
    };

    this.getProperties = function () {
        const properties = me.get("properties") || {};
        return _.extend(
            {
                isReportsV2Enabled() {
                    return properties.reports_v2_enabled === "true";
                },
            },
            properties
        );
    };

    this.getProperty = function (name) {
        return this.getProperties()[name];
    };

    this.getScopes = function () {
        return accessToken.get("scopes") || [];
    };

    this.getModeService = function () {
        return modeService;
    };
}

function getClientIdFromUrl() {
    const uri = new Uri(window.location);
    return uri.getQueryParamValue("client_id");
}

let instance;

export default {
    getInstance(accessTokenValue) {
        if (!instance) {
            instance = new Session(accessTokenValue);
        }
        return instance;
    },
};
