import { defineStore } from "pinia";
import { reactive } from "vue";
export const useDrawerTriggerStore = defineStore("drawerTriggerStore", () => {
    const state = reactive({
        drawerTriggerWorkflow: {
            isOpen: false,
        },
    });
    const toggleWorkflow = (isOpen) => {
        state.drawerTriggerWorkflow.isOpen = isOpen;
    };
    return {
        state,
        toggleWorkflow,
    };
});
