import _ from "underscore";
import { faro, getWebInstrumentations, initializeFaro, } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
const bootstrap = _.memoize(function () {
    const faro = initializeFaro({
        url: "https://metrics.extole.io/collect",
        apiKey: "QpX4hYDPCLMO9PaGtTbUZagQ5NE76Dy9",
        app: {
            name: "My Extole",
            version: "1.0.0",
            environment: "production",
        },
        instrumentations: [
            // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
            ...getWebInstrumentations(),
            // Mandatory, initialization of the tracing package
            new TracingInstrumentation(),
        ],
    });
});
const pushError = (error) => {
    bootstrap();
    faro.api.pushError(error);
};
const pushMeasurement = (type, values) => {
    bootstrap();
    faro.api.pushMeasurement({ type, values });
};
export default {
    bootstrap,
    pushMeasurement,
    pushError,
};
