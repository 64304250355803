import { useArrayMap } from "@vueuse/core";
import { useFuse } from "@vueuse/integrations/useFuse";
export const useFuzzySearch = (search, items, keys, options = {
    exactMatch: false,
    shouldSearchAnywhere: true,
    includeScore: true,
}) => {
    const { results } = useFuse(search, items, {
        matchAllWhenSearchEmpty: true,
        fuseOptions: {
            includeScore: options === null || options === void 0 ? void 0 : options.includeScore,
            threshold: (options === null || options === void 0 ? void 0 : options.exactMatch) ? 0 : 0.25,
            ignoreLocation: (options === null || options === void 0 ? void 0 : options.shouldSearchAnywhere) === undefined
                ? true
                : options === null || options === void 0 ? void 0 : options.shouldSearchAnywhere,
            keys: [...keys],
        },
    });
    return useArrayMap(results, (result) => result.item);
};
