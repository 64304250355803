export const toPromise = (deferred) => {
    return new Promise((resolve, reject) => {
        deferred
            .then((response) => resolve(response))
            .fail((response) => {
            const json = (response === null || response === void 0 ? void 0 : response.responseJSON) || {};
            reject(json);
        });
    });
};
